import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layouts/Default"
import RequestLeftCol from "../../components/layouts/partials/pages/request/RequestLeftCol"
import RequestRightCol from "../../components/layouts/partials/pages/request/RequestRightCol"
import Wizard from "../../components/forms/Wizard"
import MainData from "./../../components/layouts/partials/pages/request/MainData"

export default function Page({ data, location }) {
  return (
    <Layout
      title="Kontaktanfrage bei Dierda Finanzdienstleistungen"
      description="Hast Du Fragen? Wir sind gerne für Dich da. Fülle das Formular aus oder ruf uns gerne an"
      location={location}
      smallFooter
    >
      <section>
        <div className="flex flex-wrap">
          <div className="w-full max-w-screen-lg p-8 lg:w-8/12 lg:order-2 lg:p-16">
            <RequestRightCol>
              <h1 className="text-2xl font-bold md:text-3xl lg:text-4xl">
                Kontaktanfrage
              </h1>
              <p className="mt-3 text-lg leading-6 text-gray-700">
                Hast Du Fragen? Wir sind gerne für Dich da. Fülle das Formular
                aus oder ruf uns gerne an.
              </p>

              <dl className="mt-8 text-base leading-6 text-gray-700">
                <div>
                  <dt className="sr-only">Adresse</dt>
                  <dd>
                    <p className="mt-4 text-base leading-6 text-gray-700">
                      {
                        data.allGooglePlaces.nodes[0].result
                          .address_components[1].long_name
                      }{" "}
                      {
                        data.allGooglePlaces.nodes[0].result
                          .address_components[0].long_name
                      }
                      ,<br />
                      {
                        data.allGooglePlaces.nodes[0].result
                          .address_components[7].long_name
                      }{" "}
                      {
                        data.allGooglePlaces.nodes[0].result
                          .address_components[3].long_name
                      }
                    </p>
                  </dd>
                </div>
                <div className="mt-6">
                  <dt className="sr-only">Telefon</dt>
                  <dd className="flex">
                    <svg
                      className="flex-shrink-0 w-6 h-6 text-gray-700"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                      />
                    </svg>
                    <span className="ml-3">
                      <a href="tel:+4915164406444">01516 440 6444</a>
                    </span>
                  </dd>
                </div>
                <div className="mt-3">
                  <dt className="sr-only">E-Mail</dt>
                  <dd className="flex">
                    <svg
                      className="flex-shrink-0 w-6 h-6 text-gray-700"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                      />
                    </svg>
                    <span className="ml-3">
                      <a href="mailto:anfragen@dierda.de">anfragen@dierda.de</a>
                    </span>
                  </dd>
                </div>
              </dl>

              <div className="mt-8">
                <h2 className="font-bold text-1xl md:text-2xl lg:text-3xl">
                  Nachricht schreiben
                </h2>
                <p className="mt-3 text-lg leading-6 text-gray-700">
                  Fülle das Formular aus und wir melden uns zeitnah bei Dir.
                </p>
                <form
                  action="https://formbackend.dierda.de/contact.php"
                  method="POST"
                >
                  <input
                    type="hidden"
                    name="Anfrage"
                    value="Kontaktseite Anfragestrecke"
                  />
                  <Wizard>
                    <MainData />
                  </Wizard>
                </form>
              </div>
            </RequestRightCol>
          </div>
          <div className="w-full p-8 bg-gray-100 lg:w-4/12 lg:order-1 lg:p-16 ">
            <RequestLeftCol />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    allGooglePlaces {
      nodes {
        result {
          address_components {
            long_name
            short_name
            types
          }
        }
      }
    }
  }
`
